/**
 * Provides some basic data validation methods, to be used in determining
 * whether form fields contain acceptable data.
 */
export default {
   /**
    * Validates a US zipcode.
    *
    * This should match the regex in USPostalCode.
    *
    * @param zip the zipcode
    * @return true if the zipcode is a valid US zipcode, false otherwise
    */
   zipcode: function (zip) {
      return /^\s?(\d{5})(?:\s?[\s-]\s?(\d{4}))?/.test(zip);
   },

   /**
    * Validates a basic e-mail address
    *
    * @param email the address
    * @return true if the email *could* be a real e-mail address, false
    * otherwise
    */
   email: function (email) {
      const filter = /.+@.+\..+/;
      return filter.test(email.trim());
   },

   /**
    * Validates (loosely) a US or international phone number; basically checks
    * for 14 digits (10 digits + 4-digit extension with any interspersed
    * punctuation, so long as it falls within the characters [-+eXx .()/].
    *
    * @param phone the phone number
    * @return true if the phone number is (vaguely) valid, false otherwise
    */
   phone: function (phone) {
      return /^([ ()+./Xex-]*\d){10,}$/.test(phone);
   },

   /**
    * Validates a phone extension.
    *
    * @param ext the phone extension
    * @return true if the extension is valid, false otherwise
    */
   phoneExt: function (ext) {
      return /^[\d#*]*$/.test(ext);
   },

   /**
    * Validates a username
    *
    * @param username
    * @return true if only 3-30 characters are given with no < or >
    */
   username: function (username) {
      // Match with UserLib::INVALID_USERNAME_PATTERN.
      return /^[^<>]{3,30}$/.test(username);
   },

   orderid: function (orderid) {
      return /^(\d){5,8}$|^$/i.test(orderid);
   },

   /**
    * Determine whether or not a Wiki title is valid. This is the front-end
    * validation equivalent of WikiLib.php::isValidTitle without the numeric
    * namespace validation (that will still be handled on the server).
    *
    * @param title The Wiki title String.
    * @returns true if the given Wiki title is valid, false otherwise.
    */
   isValidWikiTitle: function (title) {
      if (title === null) {
         return false;
      }

      const trimmedTitle = title.trim();
      // Titles of '.' or '..' are picked up by Apache, so don't allow them
      if (trimmedTitle === '' || trimmedTitle == '.' || trimmedTitle == '..') {
         return false;
      }

      return !/[\t#$%&*+,/:;<>?[\\\]^{|}]/.test(title);
   },
};
